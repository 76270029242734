import React, { useState, useEffect, useRef } from 'react';
import { default_server } from '../../services/api';
import "./../../assets/styles/Section.css"


const Section = props => {

    const axios = require('axios');

    const [height, setHeight] = useState(0);
    const [vertical, setVerticalValue] = useState(window.innerHeight);

    const appState = props.parent.appState || null;
    const ref = useRef(null);
    const [payload, updatePayload] = useState(props.parent.payload);
    const [currentBookID, setCurrentSlideId] = useState(0);


    const childrens = props.children.map((children, idx) => (
        React.cloneElement(children, {
            key: idx,
            payload: payload,
            appState: appState,
            currentBookID: currentBookID,
            onSlideChange: slideIdx => setCurrentSlideId(slideIdx),
            onSurpriseComplete: () => props.parent.changeState(),
            onBoatComplete: () => props.parent.changeToLandingPage(),
        })
    ));


    useEffect(() => {

        if (currentBookID === 0) {
            return;
        }

        for (let i = 0; i < payload.listData.length; i++) {
            const element = payload.listData[i];
            if (element.bookId === currentBookID) {
                if ('bookDownloadFile' in element) {
                    return;
                }
            }
        }

        // axios.post('http://www.mocky.io/v2/5e3b77d63000007700214595', 
        axios.post(`${default_server}/getlivro/`,
            {
                "token": payload.token,
                "bookId": `${currentBookID}`
            },
            {
                timeout: 20000,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(success => {
                const newData = success.data;
                const updatedList = payload.listData.map((item, index) => {
                    if (item.bookId === currentBookID) {
                        return {
                            ...newData,
                            ...item
                        }
                    }
                    else {
                        return {
                            ...item
                        }
                    }
                });
                updatePayload({
                    ...payload,
                    listData: updatedList
                });
            })
            .catch(error => {
                console.log(error);
            });

    }, [axios, currentBookID, payload]);


    useEffect(() => {
        updateBackground();
        window.addEventListener("resize", () => updateBackground());
    });


    function updateBackground() {

        if (appState !== 'firsttimevisit' && appState !== 'landingpage') {
            return;
        }

        const HALF_SLIDE_HEIGHT = 145;
        let elementsHeight = 0;
        elementsHeight += document.getElementById('book_detail').clientHeight;
        elementsHeight += document.getElementById('review').clientHeight;
        elementsHeight += document.getElementById('others_title').clientHeight;
        elementsHeight += document.getElementById('others_books_container_overflow').clientHeight;
        elementsHeight += document.getElementById('info_buttons').clientHeight;
        elementsHeight += document.getElementById('small_buttons').clientHeight;
        elementsHeight += document.getElementById('footer').clientHeight;
        elementsHeight += HALF_SLIDE_HEIGHT;
        setHeight(elementsHeight);

        const verticalStage = document.getElementById('root').clientHeight;
        setVerticalValue(verticalStage - elementsHeight);
    }


    return (
        <section ref={ref}>
            <div className="section_background" style={{ height: height, top: vertical }} />
            <div id="frontground" className={`section_frontground ${appState === 'landingpage' ? 'section_backgrounds' : 'section_bgColor'}`}>
                {
                    childrens
                }
            </div>
        </section>
    );
}

export default Section;
